<template>
    <div class="cargo">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-dialog v-model="modalCargo" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloCargo }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmCargo" v-model="validoCargo" lazy-validation>
                                <v-text-field v-model="editCargo.nombre" :rules="reglanombreCargo"
                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                </v-text-field>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarCargo">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoCargo" color="success" class="mr-4" @click="guardarCargo">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEliminarCargo" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrarEliminarCargo">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimCargo">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- tabla -->
            <tabla :header="headersCargo" :body="cargos" tituloTabla="CARGO" :accionAgregar="true" :exportar="true"
                @recargar="listarCargo" @agregar="agregarCargo" @actualizar="editarCargo" @eliminar="eliminarCargo" />
            <!-- tabla fin -->
        </v-card>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../../components/view/Tabla";
    export default {
        name: "cargo",
        components: {
            tabla
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables cargo 
            UidCargo: null,
            buscarCargo: '',
            validoCargo: true,
            modalCargo: false,
            modalEliminarCargo: false,
            headersCargo: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / Cargo',
                    align: 'start',
                    value: 'nombre',
                },

                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            cargos: [],
            reglanombreCargo: [
                v => !!v || 'El nombre del cargo es obligatorio.'
            ],
            editedCargoIndex: -1,
            editCargo: {
                nombre: ''
            },
            defaultCargo: {
                nombre: ''
            },
            // fin variables cargo
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal cargo 
            tituloCargo() {
                return this.editedCargoIndex === -1 ? 'Nuevo Cargo' : 'Editar Cargo'
            },
        },

        watch: {
            // modal cargo
            modalCargo(val) {
                val || this.cancelarCargo()
            },
            modalEliminarCargo(val) {
                val || this.cerrarEliminarCargo()
            },
            // fin modal cargo
        },

        created() {
            // listar cargos
            this.listarCargo()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarCargo() {
                try {
                    await fetch(`${this.url_api}/cargo`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.cargos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarCargo() {
                this.modalCargo = true
            },

            editarCargo(cargo) {
                this.editedCargoIndex = this.cargos.indexOf(cargo)
                this.editCargo = Object.assign({}, cargo)
                this.modalCargo = true
                this.UidCargo = cargo.Uid;
            },

            eliminarCargo(cargo) {
                this.editedCargoIndex = this.cargos.indexOf(cargo)
                this.editCargo = Object.assign({}, cargo)
                this.modalEliminarCargo = true
                this.UidCargo = cargo.Uid;
            },

            async confirmarElimCargo() {
                try {
                    await fetch(`${this.url_api}/cargo/${this.UidCargo}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.cargos.splice(this.editedCargoIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarCargo();
            },

            cancelarCargo() {
                this.$refs.frmCargo.reset()
                this.$refs.frmCargo.resetValidation()
                this.modalCargo = false
                this.$nextTick(() => {
                    this.editCargo = Object.assign({}, this.defaultCargo)
                    this.editedCargoIndex = -1
                })
            },

            cerrarEliminarCargo() {
                this.modalEliminarCargo = false
                this.$nextTick(() => {
                    this.editCargo = Object.assign({}, this.defaultCargo)
                    this.editedCargoIndex = -1
                })
            },

            async guardarCargo() {
                if (this.$refs.frmCargo.validate()) {
                    if (this.editedCargoIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/cargo/${this.UidCargo}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editCargo)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.cargos[this.editedCargoIndex], this.editCargo);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidCargo = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/cargo`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editCargo)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarCargo()
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al guardar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarCargo()
                }
            },
            // fin CRUD cargos
        },
    }
</script>